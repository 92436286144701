<template>
	<v-container fluid v-if="artPiecesOk && propertiesOk" style="padding:0px;" :class="displaySizeClass">
		<div style="width:100%; height:128px; margin-top:-128px; background-color:gray;" v-if="isSmallScreen!='Mobile'"></div>
		<v-row no-gutters class="text-center">
			<v-col cols="12" class="py-4">
				<h1>{{shoppingCartButtonLabel[languageId]}}</h1>
			</v-col>
			<v-col cols="12" class="px-6">
				<v-row no-gutters>
                    <v-col cols="12" sm="12" md="8" lg="9" xl="9">
                        <v-card flat>
                            <v-card-title>
                                {{myOrderLabel[languageId]}}
                            </v-card-title>

                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col cols="12" v-for="(item, index) in shoppingCart" :key="index">
                                        <v-row no-gutters style="border-bottom:solid 1px black;">
                                            <v-col cols="12" style="text-align:right;">
                                                <v-btn icon color="red" @click="removeItem(item)">
                                                    <v-icon>
                                                        mdi-close
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                                                <v-container fluid class="fill-height"  style="max-height:208px; padding:4px;">
                                                    <v-img :src="require(`../assets/${getImage(item.title)}`)" max-height="200" contain ></v-img>
                                                </v-container>
                                            </v-col>
                                            <v-col cols="12"  sm="8" md="8" lg="8" xl="8" style="text-align:left;">
                                                <h3 class="mb-2" @click="$router.push(`/${baseArtPiecesUrl[languageId]}/${baseThemeUrl[languageId]}/`+item.title.split('|')[languageId])">{{item.title.split("|")[languageId]}}</h3>
                                                <v-row no-gutters>
                                                    <v-col cols="4">{{priceLabel[languageId]}}</v-col>
                                                    <v-col cols="8">$ {{(getPrice(item)*item.quantity).toLocaleString()}}</v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="4">{{sizeLabel[languageId]}} ({{sizeHint[languageId]}})</v-col>
                                                    <!-- <v-col cols="8">{{(artPieces.find(x=>x.title==item.title).orientation == 'Portrait')?(sizePortrait.find(x=>x.value == item.size).text):(size.find(x=>x.value == item.size).text)}}</v-col> -->
                                                    <v-col cols="8">{{size.find(x=>x.id == item.size).name.split("|")[metricSystemId]}}</v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="4">{{printedMediumLabel[languageId]}}</v-col>
                                                    <v-col cols="8">{{printedMedium.find(x=>x.id == item.printedMedium).name.split("|")[languageId]}}</v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="4">{{frameLabel[languageId]}}</v-col>
                                                    <v-col cols="8">{{frame.find(x=>x.id == item.frame).name.split("|")[languageId]}}</v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="4">{{linerLabel[languageId]}}</v-col>
                                                    <v-col cols="8">{{liner.find(x=>x.id == item.liner).name.split("|")[languageId]}}</v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="4" class="mb-5 mt-1" style="padding:0px;">
                                                        {{amountLabel[languageId]}}
                                                    </v-col>
                                                    <v-col cols="8">
                                                        <input type="number" min=1 max=10 v-model="item.quantity" class="form-control" disabled/>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-actions>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-row no-gutters>
                            <v-col cols="12" sm="5" md="12" lg="12" xl="12" class="ml-auto mr-auto">
                                <v-card>
                                    <v-card-title>
                                        {{orderSummaryLabel[languageId]}}
                                    </v-card-title>

                                    <v-card-text>
                                        <v-hover v-slot="{hover}">
                                            <v-row no-gutters :style="{backgroundColor: hover?'lightgray':''}">
                                                <v-col cols="4" style="text-align:left;" >{{subtotalLabel[languageId]}}</v-col>
                                                <v-col cols="8" style="text-align:right;">$ {{subtotal.toLocaleString()}}</v-col>
                                            </v-row>
                                        </v-hover>
                                        <v-hover v-slot="{hover}">
                                            <v-row no-gutters :style="{backgroundColor: hover?'lightgray':''}">
                                                <v-col cols="4" style="text-align:left;" >{{shippingLabel[languageId]}}</v-col>
                                                <v-col cols="8" style="text-align:right;">{{pendingLabel[languageId]}}</v-col>
                                            </v-row>
                                        </v-hover>
                                        <v-hover v-slot="{hover}">
                                            <v-row no-gutters :style="{backgroundColor: hover?'lightgray':''}">
                                                <v-col cols="4" style="text-align:left;" >{{shippingRegionLabel[languageId]}}</v-col>
                                                <v-col cols="8" style="text-align:right;">{{pendingLabel[languageId]}}</v-col>
                                            </v-row>
                                        </v-hover>
                                        <hr>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-btn color="secondary" block @click="checkOut()">
                                            {{verifyLabel[languageId]}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
			</v-col>
        </v-row>
        <v-row>
        </v-row>
	</v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
	export default {
		name: 'ShoppingCart',
        components:{},
        computed: {
            ...mapState("shoppingCart", 
                [
                    "shoppingCart", "artPieces", "size", "sizePortrait", "printedMedium", "frame", "liner", "artPieces", "app_token", "buyAsGuest", "languageId", "shoppingCartButtonLabel", "images", "artPiecesOk", "propertiesOk",
                    "myOrderLabel", "orderSummaryLabel", "shippingLabel", "shippingRegionLabel", "subtotalLabel", "pendingLabel", "verifyLabel", "amountLabel",
                    "size", "sizePortrait", "printedMedium", "frame", "liner", "artPieces", "shoppingCart", "collectionNames", "metricSystemId",
                    "priceLabel","sizeLabel","printedMediumLabel","frameLabel","linerLabel","inStockLabel","outOfStockLabel","sizeErrorMessage","printedMediumErrorMessage","frameErrorMessage","linerErrorMessage","sizeHint","printedMediumHint","frameHint","linerHint","addToCartLabel"
                    ,"baseArtPiecesUrl","baseThemeUrl",
                ]),
            isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth > 1920)
					windowWidth = 1920
				return windowWidth
			},
            subtotal(){
                let subTotal = 0;
                for(let i = 0; i < this.shoppingCart.length; i++){
                    var itemTotal = this.getPrice(this.shoppingCart[i]) * this.shoppingCart[i].quantity;
                    subTotal = subTotal + itemTotal;
                }
                return subTotal;
            },
            total(){
                return this.subtotal + this.shipping;
            }
        },
		data: () => ({
			pageTitle:['Carrito de compra Karlus Morales','Shopping Cart Karlus Morales'],
			pageMetaTitle:['Carrito de compra Karlus Morales','Shopping Cart Karlus Morales'],
			pageDescription:['Página con la información de productos deseados para la compra en Karlus Morales','Page with desired products to purchase in Karlus Morales'],
            pageKeywords:['Carrito','Cart'],
            metaImage:'',
			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),
            shipping:0,
            shippingRegion:"Medellín",
		}),
		metaInfo(){
			return{
				title: this.pageTitle[this.languageId],
				meta: [
					{
					vmid: 'robots',
					name: 'robots',
					content: 'noindex, nofollow',
					},
					{
					vmid: 'title',
					name: 'title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'description',
					name: 'description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'keywords',
					name: 'keywords',
					content: this.pageKeywords[this.languageId],
					},
					{
					vmid: 'og:title',
					property: 'og:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'og:description',
					property: 'og:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'og:image',
					property: 'og:image',
					content: this.metaImage,
					},
					{
					vmid: 'og:url',
					property: 'og:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:title',
					name: 'twitter:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'twitter:description',
					name: 'twitter:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'twitter:image',
					name: 'twitter:image',
					content: this.metaImage,
					},
					{
					vmid: 'twitter:url',
					name: 'twitter:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:card',
					name: 'twitter:card',
					content: 'summary',
					},
				]
			}
		},
		methods: {
            ...mapActions("shoppingCart", ["removeFromCart", "setOrders", "setMutation" ]),
            removeItem(item){
                this.removeFromCart({property:"shoppingCart",with:item});
            },
			getImage(title){
				var artPiece = this.artPieces.find(x=>x.title.includes(title))
				var images = this.images.find(x=>x.idArtPiece == artPiece.id)
				return images.base64
			},
            getPrice(item){
				var artPiece = this.artPieces.find(x=>x.title.includes(item.title))
                var size =  this.size.find(x=>x.id == parseInt(item.size))
                var printedMediumPrice =   this.printedMedium.find(x=>x.id == item.printedMedium).price
                var framePrice =  this.frame.find(x=>x.id == item.frame).price
                var linerPrice =  this.liner.find(x=>x.id == item.liner).price
                var widthXHeight = size!=null? size.width * size.height: 0
                var sizePrice = size!=null? size.price : 0
                return Math.round(artPiece.price + sizePrice*widthXHeight + printedMediumPrice*widthXHeight + framePrice*widthXHeight + linerPrice*widthXHeight);
            },
            checkOut(){
                if(this.shoppingCart.length==0 || this.total == 0) return alert('El carrito de compras esta vacío');
                this.setOrders({with:this.total, property:'totalValue'});
                this.setOrders({with:this.shipping, property:'shippingValue'});
                this.setOrders({with:this.shoppingCart, property:'orderDetails'});
                this.setMutation({with:true,property:'checkoutClick'});
                this.setMutation({with:this.total, property:'totalAmount'});
                if(this.app_token == null || this.buyAsGuest != true){
                    this.$router.push('/login');
                }
                else{
                    this.createOrder();
                }
            },
            createOrder(){
                this.$router.push('/checkout');
            }
		},
	}
</script>

<style scoped>
h3:hover{
    text-decoration:underline;
    cursor: pointer;
}
p{
    margin:0px;
}
</style>